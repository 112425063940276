// in src/App.js

import { Admin, CustomRoutes, Resource } from "react-admin";
import { BrowserRouter, Route } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { getDesignTokens } from "@usss/mui-theme";
import providers from "@usss/ra-providers";
import SubmitTicket from "./custom/submit-ticket";
import CustomDashboard from "./custom/dashboard";
import CustomLayout from "./custom/layout";
import compResources from "./permissions/competition-services";
import raceAdminsResources from "./permissions/race-admins";
import clubResources from "./permissions/club";

const App = () => {
  localStorage.setItem("env", import.meta.env.MODE);
  if (import.meta.env.MODE === "production") {
    localStorage.setItem(
      "CAS_CALLBACK",
      "https://dashboard.usskiandsnowboard.org/auth-callback"
    );
    localStorage.setItem("env", "production");
  } else if (import.meta.env.MODE === "stage") {
    localStorage.setItem(
      "CAS_CALLBACK",
      "https://dashboard-stage.usskiandsnowboard.org/auth-callback"
    );
    localStorage.setItem("env", "stage");
  } else if (import.meta.env.MODE === "dev") {
    localStorage.setItem(
      "CAS_CALLBACK",
      "https://dashboard-dev.usskiandsnowboard.org/auth-callback"
    );
    localStorage.setItem("env", "dev");
  } else {
    localStorage.setItem("CAS_CALLBACK", "http://localhost:5173/auth-callback");
  }
  return (
    <BrowserRouter>
      <Admin
        theme={createTheme(getDesignTokens("light"))}
        dashboard={CustomDashboard}
        loginPage={providers.RedirectLogin}
        authProvider={providers.authProvider}
        requireAuth
        dataProvider={providers.dataProviderManager}
        layout={CustomLayout}
      >
        {fetchResources}
      </Admin>
    </BrowserRouter>
  );
};

const fetchResources = (permissions: string[]) => {
  return (
    <>
      {clubResources(permissions)}
      {raceAdminsResources(permissions)}
      {compResources(permissions)}
      <CustomRoutes>
        <Route path="/submitTicket" element={<SubmitTicket />} />
      </CustomRoutes>
    </>
  );
};
export default App;
