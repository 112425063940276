import BugReportIcon from "@mui/icons-material/BugReport";
import { Typography } from "@mui/material";
import { createElement, useState } from "react";
import {
  AppBar,
  Layout,
  Menu,
  MenuItemLink,
  ToggleThemeButton,
  useResourceDefinitions
} from "react-admin";
import { ReactQueryDevtools } from "react-query/devtools";
import { getDesignTokens } from "@usss/mui-theme";
import { createTheme } from "@mui/material/styles";
import CustomError from "./error";
import { Popover } from "@mui/material";
import { DownhillSkiing, Link } from "@mui/icons-material";
import loadEnvironment from "../providers/environment";

// Should Only show when process.env.NODE_ENV === 'development'
const CustomLayout = (props: any) => (
  <>
    <Layout
      {...props}
      appBar={CustomAppBar}
      menu={CustomMenu}
      error={CustomError}
    />
    <ReactQueryDevtools initialIsOpen={false} />
  </>
);

const CustomAppBar = (props: any) => (
  <AppBar {...props}>
    <Typography flex="1" variant="h6" id="react-admin-title"></Typography>
    <ToggleThemeButton
      lightTheme={createTheme(getDesignTokens("light"))}
      darkTheme={createTheme(getDesignTokens("dark"))}
    />
  </AppBar>
);

export const CustomMenu = (props: Object) => {
  const env = loadEnvironment();
  const [alpineResultsPopOpen, setAlpineResultsPopOpen] = useState({
    open: false,
    target: null
  });
  const alpineResultsPopToggle = (event) => {
    setAlpineResultsPopOpen({ open: true, target: event.target });
  };
  const handleClose = () => {
    setAlpineResultsPopOpen({ open: false, target: null });
  };
  const resources = useResourceDefinitions();
  let hasAutoscoreJob = false;
  const resourceMap = Object.keys(resources).map((name) => {
    if (resources[name].options.hide) {
      // if the user has the resource, but it's hidden, show the popover instead
      if (resources[name].name === "autoscore-job") {
        hasAutoscoreJob = true;
      }
      return null;
    }
    return (
      <Menu.Item
        key={name}
        to={`/${name}`}
        primaryText={
          (resources[name].options && resources[name].options.label) || name
        }
        leftIcon={resources[name].icon && createElement(resources[name].icon)}
      />
    );
  });
  return (
    <Menu {...props}>
      <Menu.DashboardItem />
      {resourceMap}
      {hasAutoscoreJob && (
        <MenuItemLink
          key="submitResultsTop"
          onClick={alpineResultsPopToggle}
          to={"#"}
          primaryText="Alpine Results"
          leftIcon={<DownhillSkiing />}
        />
      )}
      {alpineResultsPopOpen.open === true && (
        <Popover
          open={Boolean(alpineResultsPopOpen)}
          anchorEl={alpineResultsPopOpen.target}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <MenuItemLink
            key="alpineSubmitResults"
            to="/autoscore-job"
            primaryText="Submit Alpine Results"
            leftIcon={<DownhillSkiing />}
            onClick={handleClose}
          />
          <MenuItemLink
            key="alpineSubmitResultsDocs"
            to={env.EVENT_ADMIN_DOCS_URL}
            primaryText="Event Administration Instructions Page"
            leftIcon={<Link />}
            target="_blank"
            onClick={handleClose}
          />
        </Popover>
      )}
      <MenuItemLink
        key="submitTicket"
        to="/submitTicket"
        primaryText="Support Ticket"
        leftIcon={<BugReportIcon />}
      />
    </Menu>
  );
};

export default CustomLayout;
