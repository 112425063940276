import React from "react";
import {
  List,
  Datagrid,
  TextField,
  useNotify,
  SaveButton,
  ShowButton,
  Link,
  ImageField
} from "react-admin";
import filesFilter from "./files-filter";
import { Typography } from "@mui/material";

const FilesList = () => {
  return (
    <>
      <List
        title="Job Files Search/Download"
        filters={filesFilter}
        filter={{ tags: "autoscore-job" }}
        sort={{ field: "createdAt", order: "DESC" }}
        exporter={false}
        sx={{
          "& .RaList-main": { width: "100%" },
          "& .RaList-content": { overflow: "auto" }
        }}
      >
        <Typography variant="caption">
          Use wildcard (*) at beginning or end of filename to search for all
          matches. Example: "F0186*" or "*186*"
        </Typography>
        <Datagrid bulkActionButtons={false} rowClick="show">
          <TextField source="metadata.runSeason" label="Season" />
          <TextField source="fileName" />
          <TextField source="createdAt" />
          <TextField source="metadata.target" label="Application/Target" />
          <TextField source="metadata.jobId" label="Job Run ID" />
          <TextField source="metadata.extendedType" label="File Type" />
          <ShowButton title="fileName" />
        </Datagrid>
      </List>
      <div style={{ display: "flex" }}>
        <Link
          style={{ paddingTop: "27px" }}
          target="_blank"
          to="https://forms.monday.com/forms/0c996bc0de46d5e82bac43afbcd7febb?r=use1"
        >
          <Typography
            style={{
              float: "right",
              fontVariantCaps: "all-small-caps",
              fontWeight: 200,
              marginBottom: "2px"
            }}
          >
            Open a new feature request or bug report
          </Typography>
        </Link>
      </div>
      <div>
        <Typography
          style={{
            float: "right",
            fontSize: "12px",
            fontWeight: 200,
            marginBottom: "2px"
          }}
        >
          v{import.meta.env.VITE_APP_VERSION}
        </Typography>
      </div>
    </>
  );
};

export default FilesList;
