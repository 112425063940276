import { Console } from "console";

const loadEnvironment = (): {
  PUBLIC_TOOLS_URL: string;
  EVENT_ADMIN_DOCS_URL: string;
} => {
  if (import.meta.env.MODE === "production") {
    return {
      PUBLIC_TOOLS_URL: "https://usskiandsnowboard.org/public-tools",
      EVENT_ADMIN_DOCS_URL:
        "https://usskiandsnowboard.org/event-administration-instructions"
    };
  } else if (import.meta.env.MODE === "stage") {
    return {
      PUBLIC_TOOLS_URL: "https://stage.usskiandsnowboard.org/public-tools",
      EVENT_ADMIN_DOCS_URL:
        "https://usskiandsnowboard.org/event-administration-instructions"
    };
  } else if (import.meta.env.MODE === "dev") {
    return {
      PUBLIC_TOOLS_URL: "https://dev.usskiandsnowboard.org/public-tools",
      EVENT_ADMIN_DOCS_URL:
        "https://usskiandsnowboard.org/event-administration-instructions"
    };
  } else {
    // Defaults to Local Env
    return {
      PUBLIC_TOOLS_URL: "https://dev.usskiandsnowboard.org/public-tools",
      EVENT_ADMIN_DOCS_URL:
        "https://usskiandsnowboard.org/event-administration-instructions"
    };
  }
};
export default loadEnvironment;
