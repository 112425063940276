import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Button
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Show,
  SimpleShowLayout,
  usePermissions,
  Link,
  useDataProvider
} from "react-admin";
import { useRecordContext } from "react-admin";
import { JsonField } from "react-admin-json-view";
import {
  ArrowDropDown,
  ArrowRight,
  Repeat,
  SpaRounded
} from "@mui/icons-material";
import loadEnvironment from "../../../providers/environment";

enum ProcessStatus {
  Success = "SUCCESS",
  Error = "ERROR"
}

const env = loadEnvironment();

const JobReport = () => {
  const record = useRecordContext();
  const reportOutput = record.result?.report as Array<string>;
  return (
    <>
      {!reportOutput || reportOutput.length === 0 ? (
        <>
          <Typography
            style={{
              fontFamily: "monospace",
              fontSize: "10px",
              fontWeight: 400,
              marginBottom: "1px"
            }}
          >
            <em>No report output available for this job.</em>
          </Typography>
        </>
      ) : (
        <>
          <pre>
            {reportOutput.map((r, i) => {
              return (
                <Typography
                  key={i}
                  style={{
                    fontFamily: "monospace",
                    fontSize: "10px",
                    fontWeight: 400,
                    marginBottom: "1px"
                  }}
                >
                  {r}
                </Typography>
              );
            })}
          </pre>
        </>
      )}
    </>
  );
};

const CommonErrorsChecklist = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  if (record.result && record.result.status === ProcessStatus.Error) {
    return (
      <>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDialogOpen}
          sx={{ fontSize: "12px" }}
        >
          Common errors with results XML files
        </Button>
        <Dialog
          open={open}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Common errors with results XML files"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="caption">
                In addition to the warnings and messages shown on this page,
                open the expanded results report to see additional detail from
                the results run.
                <br />
                <br />
                Some of the more common errors in results files are:
              </Typography>
              <ul>
                <li>
                  <Typography variant="caption">
                    Check gender in XML matches with the Race Codex
                  </Typography>
                </li>
                <li>
                  <Typography variant="caption">
                    Check DISC in XML matches with the Race Codex
                  </Typography>
                </li>
                <li>
                  <Typography variant="caption">
                    Use of the letter “O” instead of the number zero “0”
                  </Typography>
                </li>
              </ul>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  } else {
    return null;
  }
};

const JobWarnings = () => {
  const record = useRecordContext();
  const warningMessages = record.result.warnings as Array<string>;
  return warningMessages.length === 0 ? null : (
    <>
      <div>
        <Typography variant="caption" style={{ textDecoration: "underline" }}>
          Warnings / Info
        </Typography>
        {warningMessages.map((w, i) => {
          return (
            <Typography
              key={i}
              style={{ fontSize: "12px", fontWeight: 400, marginBottom: "1px" }}
            >
              {w}
            </Typography>
          );
        })}
      </div>
      {record.result.npsWarning ? (
        <div style={{ marginTop: "10px" }}>
          <Typography variant="subtitle2">
            Athletes with incomplete requirements should not have been allowed
            to start. Their status has been changed to NPS. They were removed
            from the penalty calculation if they were part of it.
          </Typography>
        </div>
      ) : null}
    </>
  );
};

const ResultsLink = () => {
  const [urlResolutionState, setUrlResolutionState] = useState("loading");
  const [season, setSeason] = useState("");
  const [eventCode, setEventCode] = useState(0);
  const [raceCode, setRaceCode] = useState("");
  const dp = useDataProvider();
  const record = useRecordContext();
  useEffect(() => {
    if (record.result.season && record.result.raceCode) {
      dp.getList("races", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "id", order: "1" },
        filter: {
          season: record.result?.season,
          code: record.result?.raceCode
        }
      })
        .then((result) => {
          if (result.data && result.data.length > 0) {
            setSeason(result.data[0].season);
            setRaceCode(result.data[0].code);
            setEventCode(result.data[0].eventCode);
            if (
              result.data[0].season &&
              result.data[0].code &&
              result.data[0].eventCode
            ) {
              setUrlResolutionState("complete");
            }
          }
        })
        .catch((error) => {
          console.log(`could not get race results url: ${error}`);
        });
    } else {
      setUrlResolutionState("error");
    }
  }, []);
  if (urlResolutionState === "loading") {
    return (
      <div>
        <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
          <em>Looking for published race data...</em>
        </Typography>
      </div>
    );
  }
  if (urlResolutionState === "complete") {
    return (
      <div>
        <Typography style={{ fontSize: "13px", fontWeight: 600 }}>
          Check official results:
        </Typography>
        <Link
          target="_blank"
          to={`${env.PUBLIC_TOOLS_URL}/competitions/${season}%7C${eventCode}%7C${raceCode}`}
        >
          <div
            style={{
              display: "inline-flex",
              justifyContent: "center",
              border: "1px solid",
              padding: "2px 5px 2px 5px"
            }}
          >
            <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
              Go to online race results
            </Typography>
          </div>
        </Link>
      </div>
    );
  }
  return null;
};

const JobStatusResults = () => {
  const record = useRecordContext();
  let resultDisplay = "Results are processing and will appear here shortly.";
  if (!record.result) {
    // results processing
    return (
      <>
        <div>
          <Typography variant="caption" style={{ textDecoration: "underline" }}>
            Race Score Status
          </Typography>
          <Typography style={{ fontWeight: 400, marginBottom: "2px" }}>
            Progress {record.progress}%; Status: {record.status}
          </Typography>
        </div>
        <div>
          <Typography variant="caption" style={{ textDecoration: "underline" }}>
            Messages
          </Typography>
          <Typography
            style={{ fontSize: "16px", fontWeight: 400, marginBottom: "16px" }}
          >
            <em>{resultDisplay}</em>
          </Typography>
        </div>
      </>
    );
  }
  if (record.result.status === ProcessStatus.Error) {
    resultDisplay = `${record.result.errors[0]}`;
    return (
      <div style={{ display: "flex" }}>
        <div style={{ maxWidth: "65%" }}>
          <div>
            <Typography
              variant="caption"
              style={{ textDecoration: "underline" }}
            >
              Race Score Status
            </Typography>
            <Typography
              style={{ fontWeight: 400, marginBottom: "2px", color: "red" }}
            >
              Progress {record.progress}%; Status: {record.status}
            </Typography>
          </div>
          <div>
            <Typography
              variant="caption"
              style={{ textDecoration: "underline" }}
            >
              Messages
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                fontWeight: 400,
                marginBottom: "16px"
              }}
            >
              {resultDisplay}
            </Typography>
          </div>
          <JobWarnings />
        </div>
        <div style={{ paddingLeft: "20px" }}>
          <div>
            <ResultsLink />
          </div>
          <div style={{ paddingTop: "50px" }}>
            <ScoreAnotherLink />
          </div>
        </div>
      </div>
    );
  } else {
    resultDisplay = `${record.result.success[0]}`;
    return (
      <div style={{ display: "flex" }}>
        <div style={{ maxWidth: "65%" }}>
          <div>
            <Typography
              variant="caption"
              style={{ textDecoration: "underline" }}
            >
              Race Score Status
            </Typography>
            <Typography
              style={{ fontWeight: 400, marginBottom: "2px", color: "green" }}
            >
              Progress {record.progress}%; Status: {record.status}
            </Typography>
          </div>
          <div>
            <Typography
              variant="caption"
              style={{ textDecoration: "underline" }}
            >
              Messages
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                fontWeight: 400,
                marginBottom: "16px"
              }}
            >
              {resultDisplay}
            </Typography>
          </div>
          <JobWarnings />
        </div>
        <div>
          <div>
            <ResultsLink />
          </div>
          <div style={{ paddingTop: "50px" }}>
            <ScoreAnotherLink />
          </div>
        </div>
      </div>
    );
  }
};

const JobDebugInfo = (props) => {
  const permissions = usePermissions();
  const record = useRecordContext();
  record.parameters && delete record.parameters["results_file_contents"];
  return (
    <>
      {permissions.permissions &&
      permissions.permissions.includes("competition-services") ? (
        <>
          <ColoredHLine color="black" />
          <div>
            <Typography
              variant="caption"
              style={{ textDecoration: "underline" }}
            >
              Job Run ID
            </Typography>
            <Typography>{record.id}</Typography>
            <Link
              to={`/files?displayedFilters=%7B%22metadata.jobId%22%3Atrue%7D&filter=%7B%22metadata%22%3A%7B%22jobId%22%3A%22${record.id}%22%7D%7D&order=DESC&page=1&perPage=10&sort=createdAt`}
            >
              <Typography
                style={{
                  fontSize: "14px",
                  fontVariantCaps: "all-small-caps",
                  fontWeight: 200
                }}
                variant="caption"
              >
                Show the attached file in the file browser
              </Typography>
            </Link>
          </div>
          <div>
            <Typography
              variant="caption"
              style={{ textDecoration: "underline" }}
            >
              Job Parameters / Input
            </Typography>
            <span style={{ fontSize: "11px", lineHeight: "12px" }}>
              <JsonField
                source="parameters"
                label="Job Parameters"
                jsonString={false}
                reactJsonOptions={{
                  name: null,
                  collapsed: true,
                  enableClipboard: false,
                  displayDataTypes: false
                }}
              />
            </span>
          </div>
        </>
      ) : null}
    </>
  );
};

const ShowReportDetailButton = (props) => {
  return (
    <>
      <Link
        onClick={() => props.onDebugChange(props.debug ? false : true)}
        to={"#"}
      >
        {props.debug ? (
          <span style={{ display: "flex" }}>
            <ArrowDropDown></ArrowDropDown>
            <Typography
              style={{
                float: "right",
                fontVariantCaps: "all-small-caps",
                fontWeight: 200,
                marginBottom: "2px"
              }}
            >
              Hide Expanded Results Report
            </Typography>
          </span>
        ) : (
          <span style={{ display: "flex" }}>
            <ArrowRight></ArrowRight>
            <Typography
              style={{
                float: "right",
                fontVariantCaps: "all-small-caps",
                fontWeight: 200,
                marginBottom: "2px"
              }}
            >
              Show Expanded Results Report
            </Typography>
          </span>
        )}
      </Link>
    </>
  );
};

const JobReportDetail = () => {
  const [showReport, setShowReport] = useState(false);
  return (
    <div>
      <ShowReportDetailButton
        debug={showReport}
        onDebugChange={setShowReport}
      />
      {showReport ? <JobReport /> : null}
    </div>
  );
};

const ColoredHLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 2
    }}
  />
);

const ScoreAnotherLink = () => (
  <Link to={"/autoscore-job/create"}>
    <span style={{ display: "inline-block" }}>
      <div
        style={{
          display: "inline-flex",
          justifyContent: "center",
          border: "1px solid",
          padding: "2px 5px 2px 5px"
        }}
      >
        <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
          Score another results file
        </Typography>
      </div>
    </span>
  </Link>
);

const FileSaveStatusResults = () => {
  const state = history.state;
  return state.usr?.fileSaveError ? (
    <div style={{ width: "70%" }}>
      <Typography variant="subtitle2">
        <em>
          NOTE: Results file or race packet file could not be saved at this
          time. The race results will still be processed, but please forward the
          results file and race packet via email to cs@usskiandsnowboard.org,
          and include the race code and season for this race.
        </em>
      </Typography>
    </div>
  ) : null;
};

const AutoscoreJobShow = () => {
  return (
    <Show
      title="Results Processing Detail"
      queryOptions={{ refetchInterval: 5000 }}
    >
      <SimpleShowLayout>
        <FileSaveStatusResults />
        <JobStatusResults />
        <JobReportDetail />
        <CommonErrorsChecklist />
        <JobDebugInfo />
      </SimpleShowLayout>
    </Show>
  );
};

export default AutoscoreJobShow;
