import { CalendarViewMonthTwoTone } from "@mui/icons-material";
import { SelectInput, TextInput } from "react-admin";

const AutoscoreJobFiltersAdmin = [
  // use triple underscore for period in source fields, i.e. consumer.id => consumer___id
  <TextInput
    key={"File Submitted"}
    label="File Submitted"
    source="parameters___file_name"
    parse={(v) => {
      return v.toUpperCase();
    }}
    alwaysOn
  />,
  <TextInput
    key={"Generated By"}
    label="Generated By"
    source="consumer___id"
  />,
  <TextInput key={"Season"} label="Season" source="parameters___run_season" />,
  <SelectInput
    source="status"
    choices={[
      { id: "done", name: "Complete" },
      { id: "error", name: "Error" },
      { id: "running", name: "Running" },
      { id: "queued", name: "Queued" }
    ]}
  />,
  <SelectInput
    source="parameters___rescore"
    label="Rescored"
    choices={[
      { id: true, name: "Yes" },
      { id: false, name: "No" }
    ]}
  />,
  <SelectInput
    source="parameters___sport_code"
    label="Sport"
    choices={[
      { id: "AL", name: "Alpine" },
      { id: "MA", name: "Masters" }
    ]}
  />,
  <SelectInput
    source="result___npsWarning"
    label="NPS Warnings"
    choices={[
      { id: true, name: "Yes" },
      { id: false, name: "No" }
    ]}
  />,
  <SelectInput
    source="parameters___packet_file_attached"
    label="Packet File Attached"
    choices={[
      { id: true, name: "Yes" },
      { id: false, name: "No" }
    ]}
  />
];

export default AutoscoreJobFiltersAdmin;
