import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title } from "react-admin";
import { Stack } from "@mui/material";

const SubmitTicket = () => {
  // const { permissions } = usePermissions();

  return (
    <Card>
      <Title title="Submit a Ticket" />
      <CardContent>
        <Stack spacing={2}>
          <iframe
            src="https://forms.monday.com/forms/embed/b41b35e83ba57fa93ea20393ad3e973a?r=use1"
            height="1300"
            title="monday-form"
            style={{ border: 0 }}
          ></iframe>
          {/* <Typography gutterBottom variant="h5" component="div">
            Version: {import.meta.env.VITE_APP_VERSION}
          </Typography>
          <Typography gutterBottom variant="h5" component="div">
            API: {import.meta.env.MODE}
          </Typography>
          <Typography gutterBottom variant="h5" component="div">
            Member Id: {localStorage.getItem("member-id")}
          </Typography>
          <Typography gutterBottom variant="h5" component="div">
            Permissions: {permissions}
          </Typography> */}
        </Stack>
      </CardContent>
    </Card>
  );
};
export default SubmitTicket;
