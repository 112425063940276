import { TextInput, SelectInput } from "react-admin";

const filesFilter = [
  <TextInput
    key={"filename"}
    label="File Name"
    source="fileName"
    parse={(v) => {
      return v.toUpperCase();
    }}
    alwaysOn
  />,
  <TextInput key={"runSeason"} label="Season" source="metadata.runSeason" />,
  <TextInput key={"jobId"} label="Job Run ID" source="metadata.jobId" />,
  <SelectInput
    source="metadata.extendedType"
    label="File Type"
    choices={[
      { id: "results", name: "Results" },
      { id: "packet", name: "Race Packet" }
    ]}
  />
];
export default filesFilter;
