import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";

if (import.meta.env.MODE === "production" || import.meta.env.MODE === "stage") {
  Sentry.init({
    dsn: "https://5d894a606f2d6ce592f2323f6b62b942@o276598.ingest.sentry.io/4505761125892096",
    environment: import.meta.env.MODE === "production" ? "production" : "stage",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          "https://api-stage.usskiandsnowboard.org",
          "https://api.usskiandsnowboard.org"
        ]
      }),
      new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: import.meta.env.MODE === "production" ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: import.meta.env.MODE === "production" ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
