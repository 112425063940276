import React from "react";
import {
  Show,
  TextField,
  Button,
  TopToolbar,
  useRecordContext,
  SimpleShowLayout,
  useDataProvider,
  RichTextField
} from "react-admin";
import { HorizontalRule, SaveAlt } from "@mui/icons-material";
import { purple } from "@mui/material/colors";
import { Divider, Typography, badgeClasses } from "@mui/material";

const FilesShowActions = () => {
  const record = useRecordContext();

  const downloadFile = async (url, fileName) => {
    const link = document.createElement("a");
    link.download = fileName;
    link.href = url;
    link.target = "_blank";
    link.click();
  };

  return (
    <TopToolbar>
      <Button
        alignIcon="left"
        label="Show File / Download"
        variant="contained"
        onClick={() => {
          downloadFile(record.url, record.fileName);
        }}>
        <SaveAlt />
      </Button>
    </TopToolbar>
  );
};

const DownloadButton = () => {
  const record = useRecordContext();
  return (<Button
        alignIcon="left"
        sx={{bgcolor: "purple"}}
        label="Save"
        variant="contained"
        onClick={() => {
          fetch(record.url).then(r => {
            r.blob().then(b => {
              const url = URL.createObjectURL(b.size > 0 ? b : new Blob(["no file contents received"]));
              const anchorElement = document.createElement('a');
              anchorElement.href = url;
              anchorElement.download = record.fileName;
              anchorElement.target = "_blank";
              anchorElement.click();
              anchorElement.remove();
              URL.revokeObjectURL(url);
            })
          })
          .catch(e => {
            alert(`could not donwload file: ${e}`);
          })
        }}>
        <SaveAlt />
      </Button>
  )
}

const FilesDetailShow = () => (
  <Show title="Job File Detail" actions={<FilesShowActions />}>
    <SimpleShowLayout>
      <TextField source="fileName" /> 
      <TextField source="id" label="File ID" /> 
      <TextField source="url" label="Public URL" />
      <Divider />
      <Typography variant="caption">
        XML files may open in a new tab; right-click and use 'save page as...' to download.  Otherwise, they will download automatically to your downloads folder.
      </Typography>
    </SimpleShowLayout>
  </Show>
);
export default FilesDetailShow;
