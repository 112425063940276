import { Resource } from "react-admin";
import { DownhillSkiing } from "@mui/icons-material";
import {
  AutoscoreJobCreate,
  AutoscoreJobList
} from "../resources/jobs/autoscore/autoscore-job";
import AutoscoreJobShow from "../resources/jobs/autoscore/autoscoreJobShow";

const raceAdminsResources = (permissions: string[]) => {
  if (permissions.find((role) => role === "race-admins" || role === "admin")) {
    return (
      <>
        <Resource
          name="autoscore-job"
          options={{ label: "Submit Results", hide: true }}
          icon={DownhillSkiing}
          list={AutoscoreJobList}
          show={AutoscoreJobShow}
          create={AutoscoreJobCreate}
        />
      </>
    );
  }
  return null;
};
export default raceAdminsResources;
