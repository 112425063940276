import * as React from "react";
import { Button, Title, useTranslate } from "react-admin";
import { useLocation } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import HistoryIcon from "@mui/icons-material/History";
import Typography from "@mui/material/Typography";
const CustomError = (obj: { error: any; resetErrorBoundary: () => {} }) => {
  const { pathname } = useLocation();
  const originalPathname = React.useRef(pathname);
  console.log(obj);

  const errorSubject = `A client error occurred and your request couldn't be completed`;

  // Effect that resets the error state whenever the location changes
  React.useEffect(() => {
    if (pathname !== originalPathname.current) {
      obj.resetErrorBoundary();
    }
  }, [pathname, obj.resetErrorBoundary, obj]);

  const translate = useTranslate();
  return (
    <div>
      <Title title="Error" />
      <h1>
        <ErrorIcon /> Something Went Wrong{" "}
      </h1>
      <Typography>{errorSubject}</Typography>
      {/* <div>A client error occurred and your request couldn't be completed.</div> */}
      {!import.meta.env.PROD && (
        <details>
          <h2>{translate(obj.error.toString())}</h2>
          {/* {errorInfo.componentStack} */}
        </details>
      )}
      <div>
        <Button variant="contained" onClick={() => history.go(-1)} label="Back">
          <HistoryIcon />
        </Button>
      </div>
    </div>
  );
};
export default CustomError;
