import { Resource } from "react-admin";
import { Inventory2, DownhillSkiing } from "@mui/icons-material";
import {
  AutoscoreJobCreate,
  AutoscoreJobList
} from "../resources/jobs/autoscore/autoscore-job";
import FilesList from "../resources/files/filesList";
import FilesDetailShow from "../resources/files/filesDetailShow";
import AutoscoreJobShow from "../resources/jobs/autoscore/autoscoreJobShow";

const compResources = (permissions: string[]) => {
  if (
    permissions.find(
      (role) => role === "competition-services" || role === "admin"
    )
  ) {
    return (
      <>
        <Resource
          name="autoscore-job"
          options={{ label: "Submit Results", hide: true }}
          icon={DownhillSkiing}
          list={AutoscoreJobList}
          show={AutoscoreJobShow}
          create={AutoscoreJobCreate}
        />
        <Resource
          name="files"
          options={{ label: "Job Files Admin" }}
          icon={Inventory2}
          list={FilesList}
          show={FilesDetailShow}
        />
      </>
    );
  }
  return null;
};
export default compResources;
