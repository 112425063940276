import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title } from "react-admin";
import { usePermissions } from "react-admin";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography
} from "@mui/material";
import BadgeIcon from "@mui/icons-material/Badge";

const CustomDashboard = () => {
  return (
    <Card>
      <Title title="Dashboard" />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Welcome {localStorage.getItem("firstName")}{" "}
          {localStorage.getItem("lastName")},
        </Typography>

        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
        >
          <GenerateList />
        </List>
      </CardContent>
    </Card>
  );
};
const GenerateList = () => {
  const { isLoading, permissions } = usePermissions();

  if (!isLoading && permissions) {
    const permissionsList: React.ReactElement[] = permissions.map(
      (permission: string) => {
        return (
          <ListItem key={permission} sx={{ pl: 4 }}>
            <ListItemIcon>
              <BadgeIcon />
            </ListItemIcon>
            <ListItemText primary={`${permission}`} />
          </ListItem>
        );
      }
    );
    return (
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="roles-subheader"
        subheader={
          <ListSubheader component="div" id="roles-subheader">
            Company Roles
          </ListSubheader>
        }
      >
        {permissionsList}
      </List>
    );
  }
  return null;
};
export default CustomDashboard;
